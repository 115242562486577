








import TabsView from "@/components/Tabs/Tabs.vue";
import { Component, Vue } from "vue-property-decorator";
import { tabs } from "@/data/modules";

@Component({
  components: {
    TabsView,
  },
})
export default class ModulePlatform extends Vue {
  protected tabs = tabs;

  protected currentTabIndex = 0;
}

import { createTabs } from "@/components/Tabs/models";

export const tabs = createTabs({
  name: "",
  image: {
    alt: "",
    view: "03",
    name: "01",
  },
  visible: false,
  subs: [
    {
      name: "Ядро платформы ",
      text:
        "Ядро платформы включает в себя компоненты “Данные”, “Метаданные”, “Интерфейс пользователя”, “Администрирование”, “Карта блоков данных” и предназначено для работы с данными, метаданными и связями данных." +
        "<b class='tarrif-text-bold'>Функциональные возможности:</b>" +
        "Формирование и управление моделями данных. Ведение базы нормативно-справочной информации. Ведение базы объектов учета распределенных сетей или производств.",
      image: {
        alt: "ядро платформы",
        view: "03",
        name: "01_09",
        extension: "png",
      },
    },

    {
      name: "Витрины данных",
      text:
        "Модуль предназначен для формирования представлений данных в виде витрин." +
        "<b class='tarrif-text-bold'>Функциональные возможности:</b>" +
        "Построение витрин данных, редактирование витрин, визуализация списка витрин, предоставление API для витрин. Управление настройками доступа к источникам данных.",
      image: {
        alt: "витрины данных",
        view: "03",
        name: "01_01",
        extension: "png",
      },
    },

    {
      name: "Геопредставления",
      text:
        "Модуль предназначен для создания и работы с данными,  имеющими географическую привязку." +
        "<b class='tarrif-text-bold'>Функциональные возможности:</b>" +
        "Формирование слоев визуализируемых данных на картографической подложке, управление отображением и содержанием визуализируемых слоев данных.",
      image: {
        alt: "геопредставления",
        view: "03",
        name: "01_06",
        extension: "png",
      },
    },

    {
      name: "Бизнес Анализа",
      text:
        "Модуль предназначен для анализа данных средствами графического и табличного представления." +
        "<b class='tarrif-text-bold'>Функциональные возможности:</b>" +
        "Построение дашбордов на основе блоков графического, табличного и текстового представления информации. Группировка созданных дашбордов в список.",
      image: {
        alt: "бизнес анализа",
        view: "03",
        name: "02_01",
        extension: "png",
      },
    },

    {
      name: "Деревья связей",
      text:
        "Модуль предназначен для ручного и автоматизированного поиска, визуального представления связей данных и управления этими связями." +
        "<b class='tarrif-text-bold'>Функциональные возможности:</b>" +
        "Визуальное представление связей в виде дерева, визуальное представление объектов на картографической подложке, для которых существуют связи.",
      image: {
        alt: "деревья связей",
        view: "03",
        name: "01_08",
        extension: "png",
      },
    },
    {
      name: "Управления доступом",
      text:
        "Модуль предназначен для разграничения доступа к данным, а именно: управления доступом к наборам данных." +
        "<b class='tarrif-text-bold'>Функциональные возможности:</b>" +
        "Управление доступом пользователей, разграничение доступа к данным по группам пользователей.",
      image: {
        alt: "управление доступом",
        view: "03",
        name: "01_11",
        extension: "png",
      },
    },
  ],
  description: "",
});
